import React, { useState, useContext, useEffect } from "react";
import { Container } from '../components/Components.js';
import { TopContent } from '../components/TopContent.js';
import { Translation } from 'react-i18next';
import { NavTop } from '../components/Nav.js';
import { api_get } from '../API.js';
import Breadcrumbs from '../components/Breadcrumb.js';
import PersonContext from '../PersonContext.js';
import { Loading } from "../components/Components.js";
import { isTest } from "../config.js";

export const PageGiftcards = (props) => {
  const context = useContext(PersonContext);
  const [giftcards, setGiftcards] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
      if (context?.person?.personId?.externalId == null) {
          return;
      }

      api_get(
        '/persons/' + context.person.personId.externalId + '/giftcards'
      ).then((resp) => {
        if (resp?.success && resp?.giftcards && resp.giftcards.length) {
          setGiftcards(resp.giftcards);
        }
        setLoading(false);
      }).catch((error) => {
        if (isTest()) {
          console.error(error);
        }
        setError(true);
        setLoading(false);
      });
  }, [context]);
  return (
    <Translation>
      {(t) => (
        <>
          <NavTop />
          <Breadcrumbs/>
          <TopContent h2={t("Settings.GiftcardsHeadline")} />
          <Container>
            <ListGiftcards
              isLoading={isLoading}
              giftcards={giftcards}
              error={error}
            />
          </Container>
        </>
      )}
    </Translation>
  );
};

const ListGiftcards = (props) => {
  const { isLoading, giftcards, error } = props;
  return (
    <Translation>
      {(t) => (
        isLoading ? (
          <div className="d-flex justify-content-center mt-4 mb-4">
              <Loading>{t("Settings.LoadingDetails")}</Loading>
          </div>
        ) : (
          <>
            {giftcards.length ? (
              <ul className="list list-toggle">
                  {giftcards.map((giftcard, index) => {
                      return <ListItem key={index} giftcard={giftcard} />;
                  })}
              </ul>
            ) : null}
            {!giftcards.length ? (
                <div className="mt-4 mb-4">
                    <p className="body-text">
                      {error ? t("Settings.GiftcardsUnknownError") : t("Settings.GiftcardsNoGiftcardsFound")}
                    </p>
                </div>
            ) : null}
          </>
        )
      )}
    </Translation>
  );
};

const ListItem = (props) => {
  let context = useContext(PersonContext);
  let [isOpen, setIsOpen] = useState(props.isOpen || false);
  let giftcard = props.giftcard;

  if (isOpen && isTest()) {
      console.log(giftcard);
  }

  // These will be displayed in an open giftcard list item.
  const dataLabels = [
      'purchase_time',
      'expirationdate',
      //'fullname',
      'amount',
      'amount_remaining',
  ];

  // Get giftcard value from giftcard object by the key name.
  const getGiftcardValue = (giftcard, key) => {
    let value = '';
    if (dataLabels.includes(key)) {
      value = giftcard[key];
    }

    if ('amount' === key || 'amount_remaining' === key) {
      value = value + ' kr';
    }

    return value;
  };

  return (
    <Translation>
      {(t) => (
        <li>
            <div onClick={() => { setIsOpen(!isOpen); }}>
                <div>
                    <span className="body-text color-black">
                        {giftcard?.identity}
                    </span>
                </div>
                <div className="text-end">
                    <span className="span-medium">{giftcard?.amount_remaining ?? '-'} kr</span>
                </div>
                <div className="icon toggle-down">
                    <span></span>
                </div>
            </div>
            {isOpen ? (
                <div className="more-info mt-3">
                  {dataLabels.map((key, index) => {
                    return (
                      <div key={index}>
                          <div className="d-flex info-row justify-content-between">
                              <div>
                                  <span className="caption-text-regular color-rock">
                                    {t("Settings.GiftcardsDataLabels." + key)}:
                                  </span>
                              </div>
                              <div className="">
                                  <span className="caption-text-regular color-black">
                                    {getGiftcardValue(giftcard, key)}
                                  </span>
                              </div>
                          </div>
                      </div>
                    );
                  })}
                </div>
            ) : null}
        </li>
      )}
    </Translation>
  );
};
